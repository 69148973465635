"use client";

import React from "react";
import "./HomePageSkeleton.scss";
import CustomParagraphSkeleton from "../CustomSkeletonLines/CustomSkeletonLines";
import { Col, Row } from "antd";
import CustomSkeletonBox from "../CustomSkeletonBox/CustomSkeletonBox";

const ProductPageSkeleton = ({ userAgent }) => {
  const { isMobile, isTablet, isDesktop } = userAgent;
  return (
    <>
      {isDesktop && (
        <CustomParagraphSkeleton singleHeading={true} width="100%" />
      )}
      {isDesktop ? (
        <Col span={24} className="marginLeft20 marginRight20">
          <Row
            style={{ height: "auto", minWidth: "100%", maxWidth: "100%" }}
            className="positionRelative backgroundLight paddingTop15 paddingBottom15 paddingRight10 paddingLeft10"
          >
            <Col flex="55%">
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginRight: 8 }}>
                  <CustomSkeletonBox width="100%" height={500} />
                </div>
                <div style={{ flex: 1, marginRight: 8 }}>
                  <CustomSkeletonBox width="100%" height={500} />
                </div>
              </div>
            </Col>
            <Col
              flex="45%"
              style={{
                top: 0,
                right: 0,
                bottom: 0,
                overflowY: "auto",
                maxHeight: "inherit",
                paddingLeft: "",
                position: "absolute",
                left: "55%",
              }}
            >
              <CustomParagraphSkeleton width="100%" />
            </Col>
          </Row>
        </Col>
      ) : (
        <Col flex="100%">
          <div style={{ display: "flex", padding: 10, margin: "auto" }}>
            <div style={{ flex: 1 }}>
              <CustomSkeletonBox width="100%" height={470} />
            </div>
          </div>
          <CustomParagraphSkeleton width="100%" />
        </Col>
      )}
    </>
  );
};

export default ProductPageSkeleton;
