"use client";

import { Col, Row, Breadcrumb, Typography } from "antd";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import "../../components/CollectionPage/CollectionPage.scss";
import CustomSkeletonBox from "../CustomSkeletonBox/CustomSkeletonBox";
import CustomParagraphSkeleton from "../CustomSkeletonLines/CustomSkeletonLines";
import "../../app/pages/NewArrival.scss";

const NewArrivalSkeleton = ({ userAgent }) => {
  const { isMobile, isTablet, isDesktop } = userAgent;
  const { Text } = Typography;

  return (
    <Col span={24} className="newArrival Homepage">
      <Row>
        <Col span={24}>
          <Breadcrumb className="breadcrumb">
            <BreadcrumbItem>
              <Text style={{ fontSize: "12px", color: "#ABABAB" }}>Home</Text>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Text style={{ fontSize: "12px" }}>New Arrival</Text>
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          {isDesktop ? (
            <>
              <div style={{ display: "flex", gap: "15px" }}>
                {[1, 2, 3].map((index) => (
                  <div
                    key={index}
                    style={{
                      flex: 4,
                      margin: "auto",
                    }}
                  >
                    <CustomSkeletonBox width="100%" height={400} />
                  </div>
                ))}
              </div>
              <div style={{ display: "flex", gap: "15px", marginTop: "10px" }}>
                {[1, 2, 3].map((index) => (
                  <div
                    key={index}
                    style={{
                      flex: 4,
                      margin: "auto",
                    }}
                  >
                    <CustomSkeletonBox width="100%" height={400} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div>
              <Col span={24} style={{ display: "block", textAlign: "center" }}>
                <CustomParagraphSkeleton singleHeading={true} width="100" />
              </Col>

              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  width: "95%",
                  margin: "auto",
                }}
              >
                {[1].map((index) => (
                  <div
                    key={index}
                    style={{
                      flex: 4,
                      margin: "auto",
                    }}
                  >
                    <CustomSkeletonBox width="100%" height={400} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </Col>
        <Col className="marginTop15" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              gap: "15px",
              width: "95%",
              margin: "auto",
              marginTop: "10px",
            }}
          >
            {Array.from({ length: isMobile ? 2 : 5 }, (_, index) => (
              <div key={index} style={{ flex: 1 }}>
                <CustomSkeletonBox width="100%" height={isMobile ? 300 : 450} />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default NewArrivalSkeleton;
