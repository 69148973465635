"use client";
import { useMediaQuery } from "react-responsive";

export default function MediaQueryHook() {
  const isMobile = useMediaQuery({
    query: "(max-width: 767.9px)",
  });

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991.9 });

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });

  // const isMobile = useMediaQuery({ maxWidth: 767.9 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023.9 });
  // const isDesktop = useMediaQuery({ minWidth: 1024 });

  return { isMobile, isTablet, isDesktop };
}
