"use client";

import React, { useState, useEffect } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";
import { useParams } from "next/navigation";
import "../components/Homepage/HomePage.scss";
import CustomParagraphSkeleton from "@/components/CustomSkeletonLines/CustomSkeletonLines";
import { Col, Image, Row, Spin } from "antd";
import CollectionPageSkeleton from "@/components/GlobalSkeleton/CollectionPageSkeleton";
import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import ProductPageSkeleton from "@/components/GlobalSkeleton/ProductPageSkeleton";
import NewArrivalSkeleton from "@/components/GlobalSkeleton/NewArrivalSkeleton";
import ScrollToTopComponent from "@/components/scrollToTopHook/ScrollToTop";
import BrandPageSkeleton from "@/components/GlobalSkeleton/BrandPageSkeleton";
import Cookies from "js-cookie";
import defaultImage from "../assets/images/defaultImages/alfa-loader.gif";

function Loading() {
  const userAgent = MediaQueryHook();
  const { isMobile, isTablet, isDesktop } = MediaQueryHook();
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const [pageType, setPageType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const paramsRoute = useParams();
  const styleElement = document.createElement("style");

  styleElement.innerHTML = `
  .ant-spin .ant-spin-dot-item {
    background-color: ${mainColor} !important;
  }
`;

  document.head.appendChild(styleElement);
  useEffect(() => {
    const requestUrl = paramsRoute?.handle;
    const params = requestUrl;
    window.scrollTo(0, 0);
    async function fetchPageType(params) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };

        const res = await fetch(
          `${process.env.NEXT_PUBLIC_BACKEND}/storefront/get_handle_type`,
          {
            method: "post",
            headers,
            body: JSON.stringify({
              handle: params,
              storefront: "23",
            }),
          }
        );

        if (!res.ok) {
          throw new Error("Handle Check Api Failed!");
        }

        const data = await res.json();
        setPageType(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Handle Check Api Failed!", error.message);
        setIsLoading(false);
      }
    }

    if (
      !params?.includes("checkout") &&
      !params?.includes("thankyou") &&
      !params?.includes("payment") &&
      !params?.includes("forgotpassword") &&
      !params?.includes("public") &&
      !params?.includes("new-arrival") &&
      params !== undefined &&
      params !== null &&
      params !== ""
    ) {
      fetchPageType(params);
    } else {
      setIsLoading(false);
    }
  }, [paramsRoute?.handle]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "99vh",
          background: "#f0f0f0",
        }}
      >
        <div>
          <Image
            src={defaultImage.src}
            height="auto"
            width={80}
            preview={false}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="Homepage" style={{ marginTop: "20px" }}>
      <ScrollToTopComponent />
      {window?.location?.href?.includes("new-arrival") ? (
        <NewArrivalSkeleton userAgent={userAgent} />
      ) : pageType?.type === "collection" || pageType?.type === "category" ? (
        <div>
          <CollectionPageSkeleton
            userAgent={userAgent}
            gridView={!isDesktop ? 2 : 5}
          />
        </div>
      ) : pageType?.type === "brand" ? (
        <div>
          <BrandPageSkeleton
            userAgent={userAgent}
            gridView={!isDesktop ? 2 : 5}
          />
        </div>
      ) : pageType?.type === "product" ? (
        <>
          <ProductPageSkeleton userAgent={userAgent} />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "200px",
          }}
        >
          <Image
            src={defaultImage.src}
            height="auto"
            width={80}
            preview={false}
          />
        </div>
      )}
    </div>
  );
}

export default Loading;
