"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";

const ScrollToTopComponent = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };

    handleRouteChange();
  }, [router.asPath]);

  return null;
};

export default ScrollToTopComponent;
