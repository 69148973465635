"use client";

// import React from "react";
import CustomSkeletonBox from "../CustomSkeletonBox/CustomSkeletonBox";
import { Button, Col, Image, Row, Select, Space, Typography } from "antd";
import CustomParagraphSkeleton from "../CustomSkeletonLines/CustomSkeletonLines";
import { FaFilter } from "react-icons/fa";
import "../../components/CollectionPage/CollectionPage.scss";

const CollectionPageSkeleton = ({ userAgent, gridView }) => {
  const { isMobile, isTablet, isDesktop } = userAgent;
  const { Text, Title } = Typography;

  return (
    <>
      <Row
        className={
          !isDesktop
            ? "collection_page_wrapper collection_page_wrapper_mobile"
            : "collection_page_wrapper"
        }
      >
        <Col
          lg={{ span: 24 }}
          className={
            isDesktop
              ? "collection_page_outer"
              : "collection_page_outer width100 padding0 marginAuto"
          }
        >
          <Col lg={{ span: 24 }}>
            <Row className="main-collection-inner">
              <Col
                lg={24}
                style={{
                  background: isDesktop && "#ffffff",
                }}
                className={
                  !isDesktop
                    ? "collection_page_content padding0"
                    : "collection_page_content"
                }
              >
                <Row
                  align="middle"
                  className={
                    !isDesktop ? "mobile_header_border" : "header_border"
                  }
                  style={{ justifyContent: "end" }}
                >
                  {isDesktop ? (
                    <>
                      <Col lg={18}>
                        <CustomParagraphSkeleton
                          singleHeading={true}
                          width="50"
                        />
                      </Col>
                      <Col
                        lg={isDesktop ? 6 : 24}
                        className="grid-show-wrapper"
                        style={{ justifyContent: "end" }}
                      >
                        <Space className="grid-icons-container">
                          {isDesktop && <></>}
                        </Space>

                        <Space>
                          <Text>Show</Text>

                          <Select
                            className="flexCenter"
                            value={24}
                            style={{ width: 60 }}
                            // onChange={handleChangeShowItems}
                            options={[
                              { value: "24", label: "24" },
                              { value: "48", label: "48" },
                              { value: "72", label: "72" },
                            ]}
                          />
                        </Space>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={8} className="paddingLeft10">
                        <Text className="font12">Results</Text>
                      </Col>

                      <Col span={8} className="flexCenter">
                        <Space></Space>
                      </Col>

                      <Col span={8} className="flexEnd paddingRight10">
                        <Space>
                          <Text className="font12">Show</Text>

                          <Select
                            className="flexCenter mobile_select_option"
                            value={24}
                            width={60}
                            height={24}
                            // onChange={handleChangeShowItems}
                            options={[
                              { value: "24", label: "24" },
                              { value: "48", label: "48" },
                              { value: "72", label: "72" },
                            ]}
                          />
                        </Space>
                      </Col>
                    </>
                  )}
                </Row>

                {isDesktop ? (
                  <Row align="middle" justify="flex-end">
                    <Col lg={16}>
                      <Space>
                        <Button
                          icon={<FaFilter />}
                          className="filter_btn"
                          // onClick={showFiltersDrawer}
                        >
                          Filter
                        </Button>

                        <Text className="products_count_tag">
                          Showing Products
                        </Text>
                      </Space>
                    </Col>

                    <Col lg={4} className="flexEnd"></Col>

                    <Col lg={4} className="flexEnd">
                      <Space>
                        <Text className="font12">Sort</Text>

                        <Select
                          style={{ width: 164 }}
                          defaultValue="newest"
                          className="sort_select_option"
                          value="newest"
                          options={[
                            {
                              value: "price_asc",
                              label: "Price (Low to High)",
                            },
                            {
                              value: "price_des",
                              label: "Price (High to Low)",
                            },
                            { value: "newest", label: "Newest" },
                            { value: "discounts", label: "Top Discounts" },
                            { value: "sellers", label: "Top Sellers" },
                            { value: "manual", label: "Handpicked" },
                          ]}
                        />
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <div style={{ display: "flex" }}>
                  {Array.from({ length: gridView }, (_, index) => (
                    <div key={index} style={{ flex: 1, marginRight: 8 }}>
                      <CustomSkeletonBox
                        width="100%"
                        height={!isDesktop ? 300 : 450}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  {Array.from({ length: gridView }, (_, index) => (
                    <div key={index} style={{ flex: 1, marginRight: 8 }}>
                      <CustomSkeletonBox
                        width="100%"
                        height={!isDesktop ? 300 : 450}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default CollectionPageSkeleton;
